import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image"
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import SupportPanel from "../components/SupportPanel";
import Rozcestnik from "../components/Rozcestnik";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import Breadscrum from "../components/Breadscrum";

import Forbes from "../assets/img/forbes.png";
import Seznam from "../assets/img/seznam.png";
import PozitivniZpravy from "../assets/img/pozitivniZpravy.png";
import E15 from "../assets/img/e15trans.png";
import StartupJobs from "../assets/img/startupJobs.png";
import RecenzePohreb from "../components/pohrby/RecenzePohreb";
import Kolecko1 from "../assets/img/kolecko1.svg";
import Kolecko2 from "../assets/img/kolecko2.svg";
import Kolecko3 from "../assets/img/kolecko3.svg";
import Phone from "../assets/img/telefon.svg";
import Quotes from "../assets/img/quotes.svg";
import Vesnicky from "../assets/img/vesnicky.svg";
import CzechCrunch from "../assets/img/ccLogo.svg";
import Block from "../components/Block";
import HN from "../assets/img/hn_logo.svg";





const ONasInner = styled.section`
  background: #FBFBFA;

  .jumbotron {
    .jumbotronInner {
      background: #243A58;
      position: relative;
      padding-bottom: 310px;
      padding-top: 120px;

      @media (max-width: 1150px) {
        padding-bottom: 360px;
        padding-top: 120px;
      }

      @media (max-width: 899px) {
        padding-top: 45px;
        padding-bottom: 310px;
      }

      .nadpis {
        position: relative;
        z-index: 1;
        max-width: 1150px;
        width: calc(100% - 100px);
        margin: 0 auto;

        @media (max-width: 899px) {
          width: calc(100% - 50px);
          max-width: 600px;
        }

        span {
          font-family: Visuelt-Regular;
          font-size: 22px;
          color: rgba(255,255,255,0.70);
          margin-bottom: 20px;
          display: block;

          @media (max-width: 899px) {
            font-size: 18px;
          }
        }

        h1 {
          font-family: NewKansas;
          font-size: 42px;
          color: #FFFFFF;
          line-height: 60px;

          @media (max-width: 899px) {
            font-size: 29px;
            line-height: 42px;
          }
        }
      }

      .pozadi {
        z-index: 0;
        position: absolute !important;
        top: 0;
        left: 0;
        width: calc(100%);
        height: 100%;
      }
    }

    .nasPribeh {
      position: relative;
      display: flex;
      align-items: center;
      width: calc(100% - 100px);
      max-width: 1150px;
      margin: 0 auto;
      margin-top: -200px;

      @media (max-width: 899px) {
        width: calc(100% - 50px);
        max-width: 600px;
        display: block;
        border-radius: 10px;
        overflow: hidden;
        margin-top: -250px;
        box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
      }

      @media (max-width: 450px) {
        width: calc(100% - 20px);
      }

      .velkyImg {
        max-width: 750px;
        width: calc(100% - 500px);
        box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
        border-radius: 10px;
        z-index: 0;
        position: absolute;
        left: 0;

        @media (max-width: 1150px) {
          width: calc(500px);
        }

        @media (max-width: 899px) {
          border-radius: 0;
          width: 100%;
          position: static;
          height: 300px;
        }

        @media (max-width: 600px) {
          height: auto;
        }
      }

      .nasPribehInner {
        box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
        border-radius: 10px;
        background: #fff;
        position: relative;
        z-index: 1;
        padding: 40px;
        width: 550px;
        position: absolute;
        right: 0;

        @media (max-width: 1150px) {
          width: calc(100% - 500px);
        }

        @media (max-width: 899px) {
          border-radius: 0;
          width: 100%;
          padding: 0;
          position: static;
        }

        h2 {
          font-family: NewKansas;
          font-size: 32px;
          color: #000000;
          margin-bottom: 40px;

          @media (max-width: 899px) {
            line-height: 35px;
            font-size: 25px;
            width: calc(100% - 50px);
            margin: 0 auto;
            margin-bottom: 25px;
            padding-top: 25px;
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #545454;
          line-height: 25px;

          @media (max-width: 899px) {
            width: calc(100% - 50px);
            padding-bottom: 25px;
            margin: 0 auto;
          }
        }

        span {
          font-size: 14px;
          line-height: 18px;
          opacity: 0.5;
        }
      }
    }
  }

  .coDelame {
    ul {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: calc(100% - 100px);
      max-width: 1150px;
      margin: 120px auto;

      @media (max-width: 899px) {
        display: block;
        width: calc(100% - 50px);
        max-width: 600px;
        margin: 60px auto;
      }

      li {
        width: calc((100% - 40px*2)/3);
        max-width: 340px;
        list-style: none;

        @media (max-width: 899px) {
          width: calc(100%);
          margin-bottom: 60px;
          max-width: none;
        }

        img {
          margin-bottom: 25px;
        }

        h2 {
          font-family: Visuelt-Medium;
          font-size: 22px;
          color: #000000;
          margin-bottom: 20px;
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: rgba(0,0,0,0.60);
          line-height: 25px;
        }
      }
    }
  }

  .recenze {
    background: #F3F1EC;
    padding: 60px 0;

    @media (max-width: 899px) {
      padding: 30px 0;
    }

    .recenzeInner {
      display: flex;
      align-items: stretch;
      background: #FFFFFF;
      box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
      border-radius: 20px;
      overflow: hidden;
      max-width: 1150px;
      margin: 0 auto;
      width: calc(100% - 100px);

      @media (max-width: 899px) { 
        display: block;
        width: calc(100% - 50px - 80px);
        max-width: 520px;
        padding: 40px;
      }

      @media (max-width: 500px) { 
        display: block;
        padding: 25px;
        width: calc(100% - 50px - 50px);
      }

      .left {
        width: calc(100% - 450px);
        display: flex;
        align-items: center;

        @media (max-width: 1100px) { 
          width: calc(100% - 300px);
        }

        @media (max-width: 899px) { 
          width: 100%;
        }

        .leftInner {
          max-width: 560px;
          margin: 0 auto;
          display: block;
          padding: 60px 0;
          width: calc(100% - 160px);

          @media (max-width: 1100px) { 
            width: calc(100% - 100px);
          }

          @media (max-width: 899px) { 
            padding: 0;
            width: calc(100%);
          }

          img {
            @media (max-width: 899px) { 
              width: 39px;
            }
          }

          p {
            font-family: NewKansas;
            font-size: 26px;
            color: #000000;
            line-height: 39px;
            margin-top: 22px;
            margin-bottom: 25px;

            @media (max-width: 1100px) { 
              font-size: 20px;
              line-height: 32px;  
            }

            @media (max-width: 899px) { 
              font-size: 21px;
              line-height: 33px;
              margin-top: 15px;
              margin-bottom: 25px;
            }

            @media (max-width: 500px) { 
              font-size: 19px;
              line-height: 30px;
            }

            span {
              font-family: NewKansas-Bold;
            }
          }

          .zakaznik {
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #5B5B5B;
          }
        }
      }

      .right {
        width: calc(450px);
        background: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media (max-width: 899px) { 
          width: 100%;
          display: flex;
          align-items: center;
          background: none;
          justify-content: flex-start;
        }

        span {
          display: block;

          &:first-child {
            font-family: Visuelt-Medium;
            font-size: 18px;
            color: #000000;
            line-height: 27px;  
          }

          &:last-child {
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #5B5B5B;
            line-height: 27px;
          }
        }

        .obrazek {
          height: 100%;
          margin-right: 85px;

          @media (max-width: 1100px) {
            margin-right: 50px;
          }

          @media (max-width: 899px) {
            margin-right: 0;
            margin-top: 40px;
          }
        }
      }
    }
  }

  .nezisk {
    margin: 120px auto;
    width: calc(100% - 100px);
    max-width: 1150px;

    @media (max-width: 899px) {
      width: calc(100% - 50px);
      margin: 60px auto;
      max-width: 600px;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 80px;

      @media (max-width: 899px) {
        display: block;
        margin-bottom: 50px;
      }

      .left {
        width: calc(100% - 270px - 50px);
        max-width: 750px;
        order: 1;

        @media (max-width: 899px) {
          width: calc(100%);
          max-width: none;
        }

        h2 {
          font-family: NewKansas;
          font-size: 32px;
          color: #000000;
          line-height: 45px;
          margin-bottom: 25px;

          @media (max-width: 899px) {
            font-size: 25px;
            line-height: 35px;
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #5E5E5E;
          line-height: 25px;
        }

        h3 {
          font-family: Visuelt-Medium;
          font-size: 19px;
          color: #272727;
          margin-bottom: 25px;
          margin-top: 40px;
        }

        ul {
          margin-bottom: -25px;
          max-width: 500px;

          li {
            display: inline-block;
            vertical-align: middle;
            margin-right: 35px;
            margin-bottom: 25px;

            @media (max-width: 899px) {
              margin-right: 25px;
              margin-bottom: 20px;
            }

            &:last-child {
              margin-right: 0px;
            }

            &:hover {
              img {
                filter: grayscale(100%) brightness(0%) opacity(60%);
              }
            }

            img {
              filter: grayscale(100%) brightness(0%) opacity(35%);
              max-height: 28px;
              width: auto;

              @media (max-width: 899px) {
                max-height: 25px;
              }

              @media (max-width: 350px) {
                max-height: 21px;
              }
            }

            .jobs {
              height: 20px;
              width: auto;

              @media (max-width: 900px) {
                height: 19px;
              }

              @media (max-width: 350px) {
                max-height: 18px;
              }
            }

            .pozitivni {
              max-height: 26px;
              width: auto;

              @media (max-width: 899px) {
                max-height: 23px;
              }

              @media (max-width: 350px) {
                max-height: 22px;
              }
            }
          }
        }
      }

      .right {
        order: 2;

        @media (max-width: 899px) {
          margin-bottom: 30px;
        }
      }
    }

    .bot {
      background: #F7F7F7;
      border-radius: 10px;
      width: calc(100% - 100px);
      padding: 30px 50px;

      .botInner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 899px) {
          display: block;
          text-align: center;
        }

        .vesnicky {
          width: 150px;
        }
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #5E5E5E;
        line-height: 25px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: -5px;

        @media (max-width: 999px) {
          margin-bottom: 0px;
        }
      }

      @media (max-width: 999px) {
        width: calc(100% - 60px);
        padding: 30px 30px;
      }

      @media (max-width: 899px) {
        padding: 15px;
        width: calc(100% - 30px);
      }

      .obrazek {
        @media (max-width: 899px) {
          margin: 10px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }

  hr {
    border: 0;
    border-top: 1px solid #D8D8D8;
    width: calc(100% - 100px);
    max-width: 1150px;
    margin: 120px auto;

    @media (max-width: 899px) {
      width: calc(100% - 50px);
      max-width: 600px;
      margin: 60px auto;
    }
  }

  .finalCta {
    width: 100%;
    margin: 120px auto;
    padding-bottom: 120px;
    margin-bottom: -120px;

    @media (max-width: 899px) {
      margin: 60px auto;
      padding-bottom: 60px;
      margin-bottom: -60px;
    }

    .wrapper {
      background: #FFFFFF;
      box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
      border-radius: 20px;
      width: calc(100% - 100px - 85px - 85px);
      max-width: calc(1150px - 85px - 85px);
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 60px 85px;

      @media (max-width: 1050px) {
        padding: 50px 50px;
        width: calc(100% - 100px - 50px - 50px);
      }

      @media (max-width: 899px) {
        display: block;
        padding: 40px 40px;
        max-width: calc(600px - 80px);
        width: calc(100% - 130px);
      }

      @media (max-width: 500px) {
        padding: 25px 25px;
        max-width: calc(600px - 50px);
        width: calc(100% - 100px);
      }

      @media (max-width: 420px) {
        width: calc(100% - 100px);
      }

      .left {
        max-width: 630px;
        width: calc(100% - 250px - 50px);

        @media (max-width: 899px) {
          width: calc(100%);
        }

        h2 {
          font-family: NewKansas;
          font-size: 32px;
          color: #000000;
          margin-bottom: 30px;

          @media (max-width: 899px) {
            font-size: 25px;
            line-height: 35px;
            margin-bottom: 20px;
          }
          
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #000000;
          line-height: 25px;
          margin-bottom: 40px;

          @media (max-width: 899px) {
            margin-bottom: 25px;
          }
        }
        
        .contact {
          margin-top: 30px;
          display: flex;
          align-items: center;

          @media (max-width: 374px) {
            display: block;
          }

          a {
            display: inline-flex;
            align-items: center;
            width: fit-content;
            margin-right: 30px;

            @media (max-width: 500px) {
              margin-right: 20px;
            }

            @media (max-width: 374px) {
              display: block;
              margin-bottom: 10px;
            }
            
            img {
              margin-right: 15px;

              @media (max-width: 500px) {
                margin-right: 10px;
                height: 17px;
              }
            }

            span {
              font-family: Visuelt-Bold;
              font-size: 21px;
              color: black;

              @media (max-width: 500px) {
                font-size: 16px;
              }
            }
          }

          .nonstop {
            display: inline-block;
            vertical-align: baseline;

            .greenDot {
              background: #49B25D;
              height: 10px;
              width: 10px;
              border-radius: 100%;
              animation: pulse 2s infinite;
              display: inline-block;
              vertical-align: middle;
            }

            @keyframes pulse {
              0% {
                opacity: 1;
              }

              50% {
                opacity: 0.25;
              }

              100% {
                opacity: 1;
              }
            }

            span {
              font-family: Visuelt-Medium;
              font-size: 16px;
              color: #48B15C;
              margin-left: 10px;
              vertical-align: middle;
            }
          }
        }
      }

      .right {
        @media (max-width: 899px) {
          margin-top: 20px;
        }
      }
    }
  }
`;

const ONas = ({ data }) => {

  const isPlus900 = useMediaQuery({
    query: '(min-width: 899px)'
  });

  const isLess500 = useMediaQuery({
    query: '(max-width: 500px)'
  });


  return (
    <Layout V2>





      <SEO
        title="O nás | Goodbye.cz"
        customTitle
        description="Jsme moderní pohřební služba. Snažíme se modernizovat nejen způsob organizace pohřbů, ale i postoj ke smrti jako takové."
        image={"/onasOg.png"}
      />

      <Breadscrum
        visible={false}
        stranky={[
          {name: "Domů", url: "/"},
          {name: "O nás", url: "/o-nas/"},
        ]}
      />

      <ONasInner>
        <div className="jumbotron">
          <div className="jumbotronInner">
            <div className="nadpis">
              <span>Kdo jsme a co děláme?</span>
              <h1>Pomáháme lidem v situacích, ve kterých si neví rady</h1>
            </div>

            <Img className="pozadi" alt="" fluid={data.jumboOnas.childImageSharp.fluid} />
          </div>          

          <div className="nasPribeh">
            {isPlus900 ?
              <Img className="velkyImg" alt="Goodbye tým" fluid={data.onasBig.childImageSharp.fluid} />
              :
              <Img className="velkyImg" alt="Goodbye tým" fluid={data.onasSmall.childImageSharp.fluid} />
            }

            <div className="nasPribehInner">
              <h2>Náš příběh</h2>
              <p>
                Jsme moderní pohřební služba. Snažíme se modernizovat nejen způsob organizace pohřbů, ale i postoj ke smrti jako takové. Smrt je odjakživa velké tabu a lidé o ní neradi přemýšlí, mluví a jen málokdo se na ni připravuje. Samotný proces je potom stresující, zmatený a také velmi finančně náročný. 
                <br/>
                <br/>
                Tohle jsme se rozhodli změnit. Chápeme, jak citlivé téma smrti je, a proto jsme  přišli s řešením, jak celý proces zjednodušit a udělat ho více lidským. Hlavně jsme se rozhodli mluvit o smrti. 
                <br/>
                <br/>
                Ať hledáte informace, chcete sepsat závěť nebo řešíte smrt blízkého, je naším hlavním cílem vám být oporou a maximálně vám pomoci.
              </p>
            </div>
          </div>
        </div>

        <div className="coDelame">
          <ul>
            <li>
              <img src={Kolecko1} alt="" />
              <h2>Organizujeme pohřby</h2>
              <p>Jsme moderní pohřební služba. Vše vám pomůžeme naplánovat z bezpečí domova a budeme vám oporou.</p>
            </li>
            <li>
              <img src={Kolecko2} alt="" />
              <h2>Píšeme závěti</h2>
              <p>Pomůžeme vám sepsat závěť aniž byste museli navštívit notáře. Vše vytvoříte do 15 minut. Každý dokument manuálně překontrolujeme.</p>
            </li>
            <li>
              <img src={Kolecko3} alt="" />
              <h2>Pomáháme, boříme tabu</h2>
              <p>Chceme přistupovat k tématu smrti jako v 21. století. Modernizujeme, detabuizujeme a zlidšťujeme celé odvětví.</p>
            </li>
          </ul>
        </div>

        <div className="recenze">
          <div className="recenzeInner">
            <div className="left">
              <div className="leftInner">
                <img src={Quotes} alt="" />
                <p>Zaměstnanci Goodbye byli <span>maximálně ochotní, všechno nám vysvětlili a pomohli nám zvládnout situaci,</span> která byla od začátku pro celou rodinu velkým neštěstím. Děkujeme.</p>
                <span className="zakaznik">Jana Č., Zákaznice</span>
              </div>
            </div>
            <div className="right">
              {(isPlus900) ?
                <Img className="obrazek" alt="Recenze fotka" fixed={data.recenzeSample.childImageSharp.fixed} />
              :
                <Img className="obrazek" alt="Recenze fotka" fixed={data.recenzeSampleSmall.childImageSharp.fixed} />
              }
            </div>
          </div>
        </div>
        
        <div className="nezisk">
          <div className="top">
            <div className="right">
              {(isPlus900) && 
                <Img className="obrazek" alt="Naše podpora" fixed={data.nezisk.childImageSharp.fixed} />
              }
            </div>
            <div className="left">
              <h2>Spolupracujeme s řadou neziskových organizací</h2>
              <p>Vybíráme tisíce korun pro naše partnerské neziskové organizace. To hlavně prostřednictvím štědrých odkazů našich zákazníků v jejich závětích. Vážíme si toho. Společně se tak snažíme měnit svět k lepšímu.</p>
              <h3>Napsali o nás:</h3>
              <ul>
                <li>
                  <a target="_blank" href="https://www.e15.cz/byznys/obchod-a-sluzby/digitalizovane-pohrebnictvi-start-up-goodbye-chce-revoluci-v-oboru-1379081">
                    <img src={E15} alt="Digitalizované pohřebnictví. Start-up Goodbye chce revoluci v oboru"/>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://forbes.cz/pohrebnictvi-funguje-uz-150-let-stejne-ted-to-chce-zmenit-cesky-startup/">
                    <img src={Forbes} alt="Pohřebnictví funguje už 150 let stejně. Teď to chce změnit český startup"/>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://cc.cz/misto-rakve-treba-smutecni-strom-ceske-startupy-nabidnou-ekologicke-urny-ktere-se-rozlozi/">
                    <img className="jobs" src={CzechCrunch} alt="Místo rakve třeba smuteční strom. České startupy nabídnou ekologické urny, které se rozloží"/>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://podcasty.hn.cz/c1-67010510-co-nas-ceka-po-smrti-i-silne-konzervativni-obor-jako-pohrebnictvi-ma-sve-trendy">
                    <img className="pozitivni" src={HN} alt="Co nás čeká po smrti? I silně konzervativní obor jako pohřebnictví má své trendy"/>
                  </a>
                </li>

                {/* <li>
                  <a target="_blank" href="https://www.startupjobs.cz/newsroom/chceme-rebrandovat-smrt-rika-zakladatel-startupu-goodbye-zameri-se-na-modernizaci-a-digitalizaci-pohrebnictvi">
                    <img className="jobs" src={StartupJobs} alt="„Chceme rebrandovat smrt,“ říká zakladatel startupu Goodbye. Zaměří se na modernizaci a digitalizaci pohřebnictví"/>
                  </a>
                </li> */}

                <li>
                  <a target="_blank" href="https://www.seznamzpravy.cz/clanek/ani-smrt-nas-nerozdeli-stale-vice-lidi-chce-mit-data-v-digitalnim-trezoru-172228">
                    <img className="seznam" src={Seznam} alt="Ani smrt nás nerozdělí. Stále více lidí chce mít data v digitálním trezoru"/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="bot">
            <div className="botInner">
            <Img className="obrazek" alt="Paraple" fixed={data.paraple.childImageSharp.fixed} />
              <Img className="obrazek" alt="Naděje" fixed={data.nadeje.childImageSharp.fixed} />
              <Img className="obrazek" alt="Amelie" fixed={data.amelie.childImageSharp.fixed} />
              {/* <Img className="obrazek" alt="Emoter" fixed={data.emoter.childImageSharp.fixed} /> */}

              <a href="https://www.sos-vesnicky.cz/co-delame/">
                <img className="vesnicky" src={Vesnicky} alt="SOS vesničky" />
                {/* <Img className="obrazek" alt="SOS vesničky" fixed={data.vesnicky.childImageSharp.fixed} /> */}
              </a>

              {/* <Img className="obrazek" alt="" fixed={data.pesvnouzi.childImageSharp.fixed} /> */}
              <Img className="obrazek" alt="Život dětem" fixed={data.zivotdetem.childImageSharp.fixed} />
              <Img className="obrazek" alt="Helppes" fixed={data.helppes.childImageSharp.fixed} />
              <Img className="obrazek" alt="Donio" fixed={data.donio.childImageSharp.fixed} />
            </div>
            <p>A mnoho dalších...</p>
          </div>
        </div>

        <hr />

        <div className="finalCta">
          <div className="wrapper">
            <div className="left">
              <h2>Provedeme vás složitou životní situací</h2>
              <p>Chápeme, že smrt blízkého je situace, kterou neplánujete. Zavolejte nám a my jsme připraveni vaši situaci okamžitě řešit. Se vším vám pomůžeme a budeme vám oporou.</p>
              <div className="contact">
                <a href="tel:00420315558136" className="phone">
                  <img src={Phone} alt="" />
                  <span>315 558 136</span>                
                </a>
                <div className="nonstop">
                  <div className="greenDot"></div>
                  <span>K dispozici 24/7</span>
                </div>
              </div>
            </div>
            <div className="right">
              {isLess500 ?
                <Img className="obrazek" alt="Naše podpora" fixed={data.smallTeam.childImageSharp.fixed} />
              :
                <Img className="obrazek" alt="Naše podpora" fixed={data.bigTeam.childImageSharp.fixed} />
              }
            </div>
          </div>
        </div>

      </ONasInner>

      <Rozcestnik zaveti pohrby poradna />
    </Layout>
  );
}


export const query = graphql`
  query {

    zivotdetem: file(relativePath: { eq: "zivotdetem2.png" }) {
      childImageSharp {
        fixed(height: 90, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    team: file(relativePath: { eq: "kytka.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    jumboOnas: file(relativePath: { eq: "jumboOnas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    onasBig: file(relativePath: { eq: "tymova_foto.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 730, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    onasSmall: file(relativePath: { eq: "tymova_foto_sm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    bigTeam: file(relativePath: { eq: "podpora.png" }) {
      childImageSharp {
        fixed(width: 250, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    smallTeam: file(relativePath: { eq: "podpora.png" }) {
      childImageSharp {
        fixed(width: 160, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    recenzeSample: file(relativePath: { eq: "zakaznice4.png" }) {
      childImageSharp {
        fixed(width: 340) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    recenzeSampleSmall: file(relativePath: { eq: "zakaznice4.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    nezisk: file(relativePath: { eq: "ruce.png" }) {
      childImageSharp {
        fixed(width: 270, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    neziskSmall: file(relativePath: { eq: "ruce.png" }) {
      childImageSharp {
        fixed(width: 170, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    donio: file(relativePath: { eq: "hpDonio.png" }) {
      childImageSharp {
        fixed(height: 50, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    helppes: file(relativePath: { eq: "helppes.png" }) {
      childImageSharp {
        fixed(height: 100, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    nadeje: file(relativePath: { eq: "nadejeLogo.png" }) {
      childImageSharp {
        fixed(height: 30, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    emoter: file(relativePath: { eq: "emoterY.png" }) {
      childImageSharp {
        fixed(height: 50, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    pesvnouzi: file(relativePath: { eq: "pesvnouzi.jpg" }) {
      childImageSharp {
        fixed(height: 100, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    andelstrazny: file(relativePath: { eq: "andelstrazny.png" }) {
      childImageSharp {
        fixed(height: 100, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    paraple: file(relativePath: { eq: "hpParaple.png" }) {
      childImageSharp {
        fixed(height: 100, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    amelie: file(relativePath: { eq: "hpAmelie.png" }) {
      childImageSharp {
        fixed(height: 100, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;


export default ONas;
