import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";


const LinkButtonInner = styled.div`
  a {
    font-family: Visuelt-Medium;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    width: calc(100%);
    max-width: 290px;
    margin: 0;
    display: block;
    background: #1D3A5B;
    border-radius: 3px;
    padding: 19px 0;
    text-decoration: none;

    @media (max-width: 900px) {
      padding: 18px 0;
      max-width: 260px;
    }

    &:hover {
      background: #081C33;
      cursor: pointer;
    }
  }

  button {
    font-family: Visuelt-Medium;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    width: calc(100%);
    max-width: 290px;
    margin: 0;
    display: block;
    background: #1D3A5B;
    border-radius: 3px;
    padding: 19px 0;
    text-decoration: none;
    border: none;

    @media (max-width: 900px) {
      padding: 18px 0;
      max-width: 260px;
    }

    &:hover {
      background: #081C33;
      cursor: pointer;
    }
  }
`;


const LinkButton = ({ to, text, isBig, func }) => {
  return (
    <LinkButtonInner className="linkButton" isBig={isBig}>

      {func ?
        <button onClick={func}>{text}</button>
      :
        <Link to={to}>{text}</Link>
      }
    </LinkButtonInner>
  );
}

export default LinkButton;


